
import { defineComponent } from 'vue'

export default defineComponent({
    data(){
        return{
            config: [] as any[any]
        }
    },
    mounted() {
        //set config
        this.config = JSON.parse( localStorage.getItem('config') as any[any])
    },
    methods:{
        backTo(){
            window.location.replace(this.config.backTo)
        }
    }
})
